<template>
  <div>
    <a-table :columns="columns" :dataSource="list" :pagination="paginate" @change="$emit('handleTableChange', $event)" :row-key="(record, index) => record.id + index">
      <span
        slot="id"
        slot-scope="text"
      >{{text}}</span>
      <span slot="ProductId" class="product_link" slot-scope="record" @click="$refs.previewModal.open(record.product.id)"> {{ record.product.unique_id }}</span>
      <span slot="status" slot-scope="record"> {{ getStatus(record.status) }}</span>
      <span slot="userName" slot-scope="record"> {{ record.user.full_name }}</span>
      <span slot="date" slot-scope="record"> {{ record.created_at | getFormattedDate }}</span>
      <div class="h-flex" slot="action" slot-scope="record">
        <a-popconfirm
          title="Вы уверенны что хотите отклонить?"
          ok-text="Да"
          cancel-text="Нет"
          @confirm="rejectComplaint(record.id)"
        >
          <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2">
            <i class="fe fe-trash mr-2" />
            Отклонить
          </a>
        </a-popconfirm>

        <a-popconfirm
          title="Вы уверенны что хотите отправить на редактирование?"
          ok-text="Да"
          cancel-text="Нет"
          @confirm="$refs.editModal.open(record.id)"
        >
          <a href="javascript: void(0);" class="btn btn-sm btn-light mr-2">
            <i class="fe fe-edit mr-2" />
            Требует редактирования
          </a>
        </a-popconfirm>

        <a
          href="javascript: void(0);"
          @click.prevent="$router.push({ path: `/functional/complaints/${record.id}` })"
          class="btn btn-sm btn-light"
        >
          <small>
            <i class="fe fe-more-horizontal mr-2" />
          </small>
          Просмотр
        </a>
      </div>
    </a-table>
    <to-edit-product-modal ref="editModal" @getList="$emit('getList')"/>
    <preview-modal ref="previewModal"/>
  </div>
</template>

<script>
import ToEditProductModal from '@/components/functional/complaints/ToEditProductModal'
import PreviewModal from '@/components/products/ProductPreviewModal'
import users from '@/mixins/users'

export default {
  name: 'ComplaintsTable',

  mixins: [users],

  props: {
    list: {
      type: Array,
      default: () => {
        return []
      },
    },
    paginate: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  data() {
    return {
      columns: [
        {
          title: 'ID',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' },
        },
        {
          title: 'Уникальный ID объявления',
          scopedSlots: { customRender: 'ProductId' },
        },
        {
          title: 'Пользователь',
          scopedSlots: { customRender: 'userName' },
        },
        {
          title: 'Текст жалобы',
          dataIndex: 'text',
        },
        {
          title: 'Статус',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: 'Дата',
          scopedSlots: { customRender: 'date' },
        },
        {
          title: 'Действия',
          scopedSlots: { customRender: 'action' },
        },
      ],
    }
  },
  created() {
    if (this.isManager) {
      this.columns.splice(this.columns.length - 1, 1)
    }
  },
  methods: {
    getStatus(status) {
      if (status === 1) {
        return 'Подтверждена'
      } else if (status === null) {
        return 'Ожидает рассмотрения'
      } else {
        return 'Отклонена'
      }
    },
    async rejectComplaint(id) {
      try {
        await this.$services.put(`admin/complaints/${id}`)
        this.$emit('getList')
      } catch (e) {
        console.log(e)
      }
    },
  },
  components: { ToEditProductModal, PreviewModal },
}
</script>

<style scoped lang="scss">
.product_link {
  cursor: pointer;
}
</style>
